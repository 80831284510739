<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'SubOptimalConsoleSettings',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        { label: 'Problematic setup', value: 'data.Problematic Setup', endLabel: '', roundValue: false, isPerc: false, colorize: false, isBold: false },
        { label: 'Risk', value: 'data.Risk', endLabel: '', roundValue: false, isPerc: false, colorize: false, isBold: false }
      ],
      settingsData: [],
      algoStrategyData: [
      ]
    }
  }
}
</script>

<style scoped>

</style>
